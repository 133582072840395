/* Imports */
import {
	Component,
	OnInit,
	AfterViewInit,
	OnDestroy,
	NgZone,
	ViewChild,
} from "@angular/core";
import { Router, NavigationEnd, ActivatedRoute, NavigationStart } from "@angular/router";
import { environment } from "@/environments/environment";
import { flagLayer, availableFeatureFlags } from "@shared/featureFlags";
import { analyticsLayer } from "@shared/analyticsLayer";
import { SidenavModel } from "@shared/models";

/* Services */
import {
	AlertService,
	AuthenticationService,
	FavoriteService,
	ProjectService,
	OrganizationService,
	SelectedProjectService,
	SessionService,
	ShareService,
	TitleService,
	UserService,
	UtilsService,
	containsOnlyNumbers,
	PermissionService,
} from '../../shared/services';

import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { MatMenuTrigger } from "@angular/material/menu";
import { Subscription } from "rxjs";
import { OnboardingComponent } from "../onboarding";
import { SystemUseComponent } from "../onboarding";
import { QuestionnaireComponent } from "../questionnaire";
import { AccountComponent } from "../account";
import { CreateProjectsComponent } from "../projects/create/create.projects.component";
import { RegisterModal } from "../register";
import { ConfirmationModal } from "../../components/confirmation";
import { Organization } from "@app/shared/models";
import { Location } from "@angular/common";
import _, {cloneDeep, isNil} from 'lodash';
import { MapwareFlyDialog } from "@app/components/mapware-fly/mapware-fly.dialog";
import { InformationModal } from "@app/components";
import { first } from "rxjs/operators";
import { OrderService } from "../../shared/services/order.service";

const baseProcessNavString = "Processes";
const nProcessesCutoff = 99; // Beyond 99 processes, will show 99+

@Component({
	selector: "app-main",
	templateUrl: "./main.component.html",
	styleUrls: ["./main.component.scss"],
})
export class MainComponent implements OnInit, AfterViewInit, OnDestroy {
	@ViewChild(MatMenuTrigger) menuTrigger: MatMenuTrigger;

	// `back` must be a url snippet (it is used for the back arrow and passed directly to the router)
	public currentPage: {
		name: string;
		id?: number;
		organization?: { id: number; name: string };
		back?: string;
		favorited?: boolean;
	} = { name: "", id: null };
	public prevPage: {
		name: string;
		id?: number;
		organization?: { id: number; name: string };
		back?: string;
		favorited?: boolean;
	} = { name: "", id: null };

	public user: any;
	private _profilePictureFile: object | any = [];

	public currentProfilePage: string;
	public editPassword = false;
	public uploadingFiles = false;

	public activeOrg: Organization = null;

	private isCreateProjectOpen: boolean = false;
	private routeChange: Subscription;
	public subscriptionDialog: Subscription;
	public activeOrgSubscription: Subscription;

	private systemUseModalRef: MatDialogRef<SystemUseComponent>;
	private mapwareFlyDialogRef: MatDialogRef<MapwareFlyDialog>;
	private onboardingModalRef: MatDialogRef<OnboardingComponent>;
	private questionnaireModalRef: MatDialogRef<QuestionnaireComponent>;

	public bannerNotice = {
		title: null,
		text: ""
	};

	public appName = "Mapware";
	public primaryItem = {
		name: "Create Project",
		tooltip: "Create Project",
		icon: "add",
		active: false,
	};
	public env = {
		frontend: environment.envName, 
		api: {
				'https://api.mapware.com': 'production',
				'https://dab-api.staging.mapware.com': 'staging',
				'http://localhost:8081': 'local'
			}[environment.apiUrl] || 'production'
	};
	public titleBarCssClass: string = '';

	envNavItems = environment.envName.includes("drone")
		? [
				{
					name: "Sandbox",
					tooltip: "Sandbox",
					route: ["sandbox"],
					icon: "category",
					active: false,
				},
			]
		: [];

	public navItems = [
		{
			name: "Projects",
			tooltip: "Projects",
			route: ["projects"],
			icon: "folder_open",
			active: false,
		},
		{
			name: "Favorites",
			tooltip: "Favorites",
			route: ["favorites"],
			icon: "star",
			active: false,
		},
		{
			name: "Trash",
			tooltip: "Trash",
			route: ["trash"],
			icon: "delete_outline",
			active: false,
		},
	].concat(this.envNavItems);

	private _processesPageNavItem = {
		name: baseProcessNavString,
		tooltip: "Processes",
		route: ["processes"],
		icon: "memory",
		active: false,
	};

	private _aiModelListNavItem = {
		name: "AI Models",
		tooltip: "AI Models",
		route: ["aimodellist"],
		icon: "track_changes",
		active: false,
	};

	public supportLink = environment.supportEmail;
	public supportGuide = environment.supportGuide;
	public isMobile: boolean = false;
	public titleChanging: boolean = false;

	public sidenavInput: SidenavModel = {
		appName: this.appName,
		primaryItem: this.primaryItem,
		navItems: this.navItems,
		supportLink: this.supportLink,
		supportGuide: this.supportGuide,
	};

	constructor(
		public _router: Router,
		public dialog: MatDialog,
		private _dialog: MatDialog,
		private _alertService: AlertService,
		private _activatedRoute: ActivatedRoute,
		private _authenticationService: AuthenticationService,
		private _permissionService: PermissionService,
		private _ngZone: NgZone,
		private _route: ActivatedRoute,
		private _projectService: ProjectService,
		private _orgService: OrganizationService,
		private _userService: UserService,
		private _favoriteService: FavoriteService,
		private _organizationService: OrganizationService,
		private _selectedProject: SelectedProjectService,
		private _shareService: ShareService,
		private _utilsService: UtilsService,
		private _titleService: TitleService,
		private _location: Location,
		private _orderService: OrderService
	) {
		this.isMobile = this._utilsService.getIsMobile();

		this._utilsService.getScreenResize().subscribe((isMobile) => {
			this.isMobile = isMobile;
		});

		this.user = this._authenticationService.user;

		_activatedRoute.queryParams.subscribe(({ share_guid }) => {
			if (
				share_guid &&
				!flagLayer.isEnabled(availableFeatureFlags.disableSharing)
			) {
				this.joinByShareLink(share_guid);
			} else if (
				share_guid &&
				flagLayer.isEnabled(availableFeatureFlags.disableSharing)
			) {
				this._alertService.notify(
					"The shared project could not be accessed."
				);
			}
		});

		this.bannerNotice = flagLayer.isEnabled('banner-notice');

		this.titleBarCssClass = this.env.frontend === 'staging' ? 'staging-title-bar' :
			this.env.frontend === 'development' || this.env.frontend === 'local' ? 'local-title-bar' : '';

		
	} // End-of constructor

	ngOnInit() {
		if (this._route.snapshot.routeConfig.path === "unsubscribe") {
			this.currentPage = { name: "Projects" };
			this.openAccountModal("alert", true);
		}

		this.setTitle();

		this.routeChange = this._router.events.subscribe((event) => {
			if (event instanceof NavigationEnd) {
				this.setTitle();
				this.prevPage = this.currentPage;
				this.checkToShowPopups();
			}
		});

		this.activeOrgSubscription = this._orgService
			.getActiveOrg()
			.subscribe(({ organization, preventReload }) => {
				if (this.checkOrgChange(organization) && !preventReload) {
					if (this.activeOrg) {
						this._router.navigateByUrl("/");
					}
					this.subscriptionDialog?.unsubscribe();
					this.primaryItem.active =
						this.shouldEnableCreate(organization);
					this.activeOrg = organization;

					this.updateProcessesText();
					this.updateAiModelList();

					if (this.showPaywall(organization)) {
						this.openPaywallDialog(organization);
					}
				} else if (this.checkOrgChange(organization) && preventReload) {
					Object.assign(this.activeOrg, organization);
				}
				// else, no need to update
			});
	} // End-of OnInit

	updateProcessesText() {
		const alreadySetText = this.navItems.find(
			(x) => x.tooltip === "Processes"
		); // Prevents duplicates in the rare event of a re-call
		if (
			flagLayer.isEnabled(availableFeatureFlags.processesPage) &&
			!alreadySetText
		) {
			this.navItems.splice(2, 0, this._processesPageNavItem);
		}
	}

	updateAiModelList() {
		const aiModelsTabText = this.navItems.find(
			(x) => x.name === "AI Models"
		); // Prevents duplicates in the rare event of a re-call
		if (flagLayer.isEnabled(availableFeatureFlags.aiModelList) &&
			!aiModelsTabText
		) {
			this.navItems.splice(3, 0, this._aiModelListNavItem);
		}
	}

	checkOrgChange = (organization: Organization) =>
		organization?.id && !_.isEqual(organization, this.activeOrg);

	openPaywallDialog(org: Organization): void {
		const confirmOptions = {
			title: "Please Select a Subscription",
			text: `Your team "${org.name}" does not have an active subscription. In order to use Mapware with this team, please sign up for one of our subscription options.`,
			buttonText: "View Subscription Options",
			cancelText: "Close",
		};

		if (!this.subscriptionDialog || this.subscriptionDialog.closed) {
			this.subscriptionDialog = this._dialog
				.open(ConfirmationModal, { data: confirmOptions })
				.afterClosed()
				.subscribe(
					(rtn) => {
						this.subscriptionDialog.unsubscribe();
						if (rtn) this.openAccountModal("plan");
					},
					(err) => {
						console.error(
							"Error with Confirmation for Subscription",
							err
						);
					}
				);
		}
	}

	async ngAfterViewInit() {
		this.checkToShowPopups();
	}

	checkToShowPopups() {
		const hasUser = this.user && typeof this.user.id != "undefined";
		const shouldOpenSystemUseBanner =
			hasUser &&
			flagLayer.isEnabled(availableFeatureFlags.systemUseBanner) &&
			!SessionService.get("agreed");
		const shouldOpenOnboarding =
			hasUser &&
			!this.user.descriptors.userOnboardingComplete &&
			!flagLayer.isEnabled(availableFeatureFlags.disableOnboarding);
		const shouldOpenQuestionnaire =
			hasUser &&
			!this.user.descriptors.questionnaire &&
			!flagLayer.isEnabled(availableFeatureFlags.disableQuestionnaire);
		const shouldMapwareFlyPromo =
			hasUser &&
			!shouldOpenOnboarding &&
			!this.user.descriptors.mapwareflypromo;

		//Disable onboarding skips both tutorial and the marketing questions
		if(shouldOpenSystemUseBanner) {
			this.openSystemUseModal();
		} else if (shouldOpenQuestionnaire) {
			this.openQuestionnaireModal(shouldOpenOnboarding);
		} else if (shouldOpenOnboarding) {
			this.openOnboardingModal();
		} else if (shouldMapwareFlyPromo) this.openMapwareFlyDialog();
	}

	openCreateProject(event) {
		if (
			!this.isCreateProjectOpen &&
			this.shouldEnableCreate(this.activeOrg)
		) {
			const dialogRef = this.dialog.open(CreateProjectsComponent);
			this.isCreateProjectOpen = true;
			dialogRef.afterClosed().subscribe((event) => {
				this.isCreateProjectOpen = false;
			});
		}
	}

	joinByShareLink(share_guid): void {
		this._shareService
			.joinShare(share_guid)
			.then(({ project }) => {
				const confirmOptions = {
					title: `A project was shared with you!`,
					text: `The project ${project.name} has been shared with you. Would you like to open the project?`,
					buttonText: "Open project",
					cancelText: "Close",
				};

				this._dialog
					.open(ConfirmationModal, { data: confirmOptions })
					.afterClosed()
					.subscribe(
						(rtn) => {
							if (rtn)
								this._router.navigateByUrl(
									`/projects/view/${project.id}`
								);
						},
						(err) => {
							console.error(
								"Error in confirmationModal subscription",
								err
							);
						}
					);
			})
			.catch((err) => {
				console.error(err);
				this._alertService.notify(
					err === 412
						? "Your account already has access to the shared project"
						: "Something went wrong, please check the link and try again",
					"error"
				);
			});
	}

	async setTitle(): Promise<void> {
		const completeURL = this._router.url;
		const [baseURL, params] = completeURL.split('?');
		const urlSplit = baseURL.split("/");
		const url = urlSplit[1];
		const projId = urlSplit.includes("projects") && urlSplit.find(x => containsOnlyNumbers(x));
		const forceRefresh = !isNil(projId) && this.currentPage.id !== parseInt(projId);
		this.titleChanging = forceRefresh;

		this.navItems.forEach((x) => (x.active = false));

		const currentNav = this.navItems.find((x) => x.route[0] === url);
		if (currentNav) {
			currentNav.active = true;
			if (currentNav.name === "Projects" && baseURL.includes("/view/")) {
				if (this._selectedProject.isEmpty() || forceRefresh) {
					const projectId = Number(baseURL.match(/(\d+)/)[0]);

					const project = flagLayer.isEnabled(availableFeatureFlags.apiV2Routes)
						? await this._projectService.getByIdV2(projectId)
						: await this._projectService.getById(projectId);

					const favInfo = await this._favoriteService.getByProjectId(
						projectId
					);

					const org = project.organization
						? project.organization
						: await this._organizationService.getById(
								project["organization_id"]
							);

					this.currentPage = {
						...currentNav,
						name: project.name,
						id: project.id,
						favorited: !!favInfo?.id,
						organization: {
							id: org.id,
							name: org.name,
						},
						back: "/projects/list",
					};
				} else {
					const { name, id, favorited, organization } =
						this._selectedProject.getSelectedProject();
					this.currentPage = {
						...currentNav,
						name,
						id,
						favorited,
						organization,
						back: "/projects/list",
					};
				}
			} else if (currentNav) {
				this.currentPage = currentNav;
			} else {
				this.currentPage = { name: "Unknown" };
			}
			this.updateTabTitle();
			analyticsLayer.trackPage({ page_location: baseURL });
		}
		this.titleChanging = false;
	} // End-of setTitle

	goBack(): void {
		if (
			this.prevPage &&
			(this.prevPage.name === "Favorites")
		) {
			this._location.back();
		} else {
			this._router.navigateByUrl(this.currentPage.back);
		}
		this._selectedProject.clearSelection();
	}

	async logout(): Promise<any> {
		await this._orgService.clearActiveOrg();
		this._authenticationService.logout().then(() => {
			this._orgService.clearOrgChange();
			this._ngZone.run(() => {
				this._router.navigate(["login"]);
			});
		});
	} // End-of logout

	ngOnDestroy() {
		this.routeChange?.unsubscribe();
		this.subscriptionDialog?.unsubscribe();
		this.activeOrgSubscription?.unsubscribe();
	} // End-of ngOnDestroy

	openSystemUseModal(): void {
		if (!this.systemUseModalRef) {
			this._alertService.setAlertInMenu(true);
			this.systemUseModalRef = this.dialog.open(SystemUseComponent);
			this.systemUseModalRef.afterClosed().subscribe((rtn) => {
				this._alertService.setAlertInMenu(false);
			});
		}	
	}

	setUserOnboard(): void {
		this.user.descriptors.userOnboardingComplete = true;
		const user = this.user;
		this._userService.update(user).then(
			(rtn) => {
				SessionService.set("user", user);
			},
			(err) => {
				console.error(err);
			}
		);
	}

	openOnboardingModal(): void {
		if (!this.onboardingModalRef) {
			// Prevent the modal from opening twice, which occurs when there are org errors
			this._alertService.setAlertInMenu(true);
			this.onboardingModalRef = this.dialog.open(OnboardingComponent);
			this.onboardingModalRef.afterClosed().subscribe((rtn) => {
				this._alertService.setAlertInMenu(false);
				this.setUserOnboard();
			});
		}
	}

	openQuestionnaireModal(goToOnboarding?: boolean): void {
		if (!this.questionnaireModalRef) {
			// Prevent the modal from opening twice, which occurs when there are org errors
			this._alertService.setAlertInMenu(true);
			this.questionnaireModalRef = this.dialog.open(
				QuestionnaireComponent,
				{ disableClose: true }
			);
			this.questionnaireModalRef.afterClosed().subscribe((rtn) => {
				this._alertService.setAlertInMenu(false);
				if (goToOnboarding) {
					this.openOnboardingModal();
				}
			});
		}
	}

	openMaintenanceBannerDialog(): void {
		this.dialog.open(InformationModal, {
			data: {
				buttonText: "Close",
				text: this.bannerNotice.text,
				title: this.bannerNotice.title,
			},
		});
	}

	openMapwareFlyDialog(): void {
		if (
			!this.mapwareFlyDialogRef &&
			flagLayer.isEnabled(availableFeatureFlags.mapwareFlyPromo)
		) {
			// Prevent the modal from opening twice, which occurs when there are org errors
			this._alertService.setAlertInMenu(true);
			this.mapwareFlyDialogRef = this.dialog.open(MapwareFlyDialog, {
				disableClose: true,
			});
			this.mapwareFlyDialogRef
				.afterClosed()
				.pipe(first())
				.subscribe(() => {
					this._alertService.setAlertInMenu(false);
					this.mapwareFlyDialogRef = null;
					this.setUserMapwareFlyPromo();
				});
		}
	}

	setUserMapwareFlyPromo(): void {
		this.user.descriptors.mapwareflypromo = true;
		const user = this.user;
		this._userService.update(user).then(
			() => {
				SessionService.set("user", user);
			},
			(err) => {
				console.error(err);
			}
		);
	}

	openAccountModal(route: Object, isUnsubscribe?: boolean): void {
		this._alertService.setAlertInMenu(true);
		const dialogRef = this._dialog.open(AccountComponent, { data: route });
		dialogRef.componentInstance.isUnsubscribe = isUnsubscribe
			? isUnsubscribe
			: false;
		dialogRef.afterClosed().subscribe((orgData) => {
			this.updateTabTitle();
			this._alertService.setAlertInMenu(false);
			if (orgData) {
				this.openRegisterModal(orgData);
			}
		});
	}

	openRegisterModal(org): void {
		this._alertService.setAlertInMenu(true);
		const dialogRef = this.dialog.open(RegisterModal, { data: org });
		dialogRef.afterClosed().subscribe((rtn) => {
			//TODO: come up with a more descriptive name?
			this._alertService.setAlertInMenu(false);
			if (rtn === "account") {
				this.openAccountModal("plan");
			}
		});
	}

	openLink(link: "termsOfService" | "privacyPolicy" | "cookiePolicy"): void {
		window.open(environment[link], "_blank");
	}

	private updateTabTitle(): void {
		this._titleService.setTitle(this.currentPage.name);
	}

	showPaywall = (org: Organization): boolean =>
		org && !(org.subscribed || org.isFromProjectShare);

	showBack = () => {
		const baseURL = this._router.url;
		return baseURL.includes("/view/");
	}

	shouldEnableCreate = (org: Organization): boolean => !!org?.subscribed
		&& this._permissionService.compareToOrgPermissions(org, "process", this.user.id);

} // End-of class MainComponent
