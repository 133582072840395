

<div class="container" id="mainContainer">
	<div class="title-bar" [ngClass]="titleBarCssClass">
		<span class="flex-row-center">
			<div class="sidenav-title" id="sidenav-title" [routerLink]="'/'">
				<img [ngClass]="!isMobile ? 'mapware-logo' : 'aa-logo'" [src]="!isMobile ? 'assets/images/Mapware_Logo.png' : 'assets/icons/aa_logo.png'">
				<div *ngIf="env.frontend !== 'production'" class="environment-details H7WhiteRegular">
					<ng-container *ngIf="env.frontend === 'staging' && env.api === 'staging'; else loggedOut">
						{{env.frontend | titlecase}}
					</ng-container>
					<ng-template #loggedOut>
						{{env.frontend | titlecase}} : {{env.api | titlecase}}
					</ng-template>
				</div>
			</div>
			<app-search></app-search>
		</span>
		<ng-container *ngIf="bannerNotice.title">
			<div class="maintenance-banner-container">
				<div class="pulse" (click)="openMaintenanceBannerDialog()">
					<mat-icon class="bell-icon">
						notification_important
					</mat-icon>
				</div>
				<span class="banner-title ellipsis tablet-hidden">
					{{ bannerNotice.title }}
				</span>
			</div>
		</ng-container>
		<div class="title-text" *ngIf="currentPage">
			<ng-container *featureEnabled="'!remove-subscription-required'">
				<ng-container *featureEnabled="'!hide-credits'">
					<ng-container *ngTemplateOutlet="t_creditsMenu"></ng-container>
				</ng-container>
			</ng-container>
			<ng-container *featureEnabled="'remove-subscription-required'">
				<ng-container *featureEnabled="'!hide-credits'">
					<app-team-select class="phone-hidden"></app-team-select>
					<ng-container *ngIf="!isMobile">
						<app-trial (upgradeAccount)="openAccountModal($event);"></app-trial>
					</ng-container>
				</ng-container>
			</ng-container>

			<!--@remove-end remove-subscription-required -->
			<ng-container *ngTemplateOutlet="t_logoutMenu"></ng-container>
		</div>
	</div>

	<app-sidenav
		(primaryButtonEvent)="openCreateProject($event)"
		[sidenavInput]="sidenavInput"
		[paywall]="showPaywall(activeOrg)"
		(openFly)="openMapwareFlyDialog()">
		<app-paywall [disabled]="showPaywall(activeOrg)" [showInfo]="true" (upgradeAccount)="openAccountModal($event);">
			<router-outlet></router-outlet>
		</app-paywall>
	</app-sidenav>
</div>

<!-- TODO: @remove remove-subscription-required -->
<ng-template #t_creditsMenu>
	<span class="bar-items" *ngIf="!isMobile">
		<app-team-select class="phone-hidden"></app-team-select>
		<app-trial (upgradeAccount)="openAccountModal($event);"></app-trial>
		<app-admin-view></app-admin-view>
	</span>
</ng-template>
<!--@remove-end remove-subscription-required -->

<ng-template #t_logoutMenu>
	<button mat-button [matMenuTriggerFor]="logoutMenu" class="logout-menu-button" data-testid="logout-menu">
		<span class="logout-container">
			<aerial-avatar class="title-avatar H7WhiteRegular" *ngIf="user">
				<ng-container *ngIf="user.avatar">
					<img style="width: 100%; height: 100%; border-radius: 48px;" [src]="user.avatar"
						onerror="this.src='assets/images/placeholder_avatar.png'">
				</ng-container>
				<ng-container *ngIf="!user.avatar && user.first_name">
					{{ user.first_name[0] + user.last_name[0] }}
				</ng-container>
			</aerial-avatar>
			<mat-icon *ngIf="!isMobile">expand_more</mat-icon>
		</span>
	</button>
</ng-template>

<mat-menu class="logout-menu" #logoutMenu>
	<ng-template matMenuContent>
		<button mat-icon-button class="floating-close-button" matTooltip="Close" (click)="menuTrigger.closeMenu();">
			<mat-icon>close</mat-icon>
		</button>
		<span class="logout-menu-container" (click)="$event.stopPropagation();">
				<div class="top-profile-bar">
					<div class="H6WhiteSemibold">{{user.first_name + ' ' + user.last_name}}</div>
					<div class="H7WhiteRegular">{{user.email}}</div>
					<div class="profile-bar-buttons">
						<button mat-flat-button
								class="mini-button profile-button"
								color="secondary"
								(click)="openAccountModal('profile'); menuTrigger.closeMenu();"
								data-testid="profile-account-menu">
							Manage Profile
						</button>
						<button
							mat-flat-button
							color="primary"
							class="mini-button"
							(click)="logout(); menuTrigger.closeMenu();">
							Logout
						</button>
					</div>
				</div>

				<div style="margin: 8px 0;" class="centered-menu-text">
					<mat-list>
						<mat-list-item *featureEnabled="'!disable-payments'" (click)="openAccountModal('plan'); menuTrigger.closeMenu();"
									   data-testid="plan-account-menu">
							Subscriptions
						</mat-list-item>
						<mat-list-item (click)="openAccountModal('teams'); menuTrigger.closeMenu();"
							data-testid="teams-account-menu">
							Teams & Members
						</mat-list-item>
						<mat-list-item (click)="openAccountModal('receipt'); menuTrigger.closeMenu();"
							data-testid="receipt-account-menu">
							Processing Receipts
						</mat-list-item>
						<mat-list-item (click)="openAccountModal('alert'); menuTrigger.closeMenu();"
							data-testid="alert-account-menu">
							Alerts & Notifications
						</mat-list-item>
					</mat-list>
						<span class="menu-items" *ngIf="isMobile">
							<div class="item">
								<app-team-select></app-team-select>
							</div>
						</span>
				</div>

				<div class="policy-container">
					<span class="policy H7Black500Regular" (click)="openLink('termsOfService'); menuTrigger.closeMenu();">Terms of Service</span>
					<span style="margin: 0 16px;">•</span>
					<span class="policy H7Black500Regular" (click)="openLink('privacyPolicy'); $event.stopPropagation(); menuTrigger.closeMenu();">Privacy Policy</span>
				</div>
			</span>
	</ng-template>
</mat-menu>
